import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  name: 'Nicolas Gomez',
  age: 31,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {},
})

export const selectedUser = (state) => state.user
export default userSlice.reducer
