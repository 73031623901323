import { createStore } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import createReducer from './stores'

const middlewares = []

const initialState = {
  sidebarShow: true,
}

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: true,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
})

export default store
